const en = {
  header: "Knowledge and experience",
  questions: {
    AE1: "Do you have any previous experience with financial instruments?",
    AE2: "How long have you had knowledge and experience with this asset class?",
    AE3: "What was the average investment?",
    AE4: "How often do you invest in the different asset classes per year?",
    AE5: "Do you do business with or in virtual currencies?",
    AE6: "What percentage of your transctions do you process with virtual currencies?",
    AE7: "Have you gained relevant capital markets experience through your job or education?",
    AE8: "How did you come to have these experiences?",
  },
  options: {
    AE1: {
      "1": "I do not wish to provide this information",
      "2": "Yes",
      "3": "No",
    },
    AE2: {
      "1": "Less than 1 year",
      "2": "1-3 years",
      "3": "More than 3 years",
    },
    AE3: {
      "1": "I have never invested",
      "2": "Up to 5,000€",
      "3": "Up to 25,000€",
      "4": "More than 25,000€",
    },
    AE4: {
      "1": "Less than 5 transactions",
      "2": "More than 5 transactions",
    },
    AE5: {
      "1": "Yes",
      "2": "No",
    },
    AE6: {
      "1": "Less than 50%",
      "2": "More than 50%",
    },
    AE7: {
      "1": "Yes",
      "2": "No",
    },
    AE8: {
      "1": "Relevant occupation (e.g banking and/or insurance)",
      "2": "Relevant studies/training (e.g business administration and/or banking management assistant)",
      "3": "Both",
    },
  },
  errors: {
    selectRequired: "Please make a selection.",
  },
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
