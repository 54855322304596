const en = {
  header: "Other information",
  questions: {
    USTax: "Do you pay taxes in the U.S?",
    USCitizen: "Are you a U.S citizen?",
    PreCheckPEP: "Are you a politically exposed person?",
    Taxdomicile: "Do you have a different or additional tax domicile?",
    TaxdomicileCountry: "Please enter your additional tax domicile:",
  },
  options: {
    "0": "No",
    "1": "Yes",
  },
  errors: {
    selectRequired: "Please make a selection.",
  },
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
