import { SmartContract } from "types";
import * as Blockchain from "../../../global-blockchain-config";

const MERCHANT_LIST = {
  Testnet: [{ id: "27", address: "0x00007869636b656d" }],
  Mainnet: [{ id: "85", address: "0x00007069636b656d" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 0, address: "0x10007069636b656d" },
  Mainnet: { id: 0, address: "0x10007069636b656d" },
};

const WALLET_VIEWER_ID = {
  Testnet: 14,
  Mainnet: 31,
};

const KYC_MERCHANT_ID = {
  Testnet: 1,
  Mainnet: 1,
};

const DISCOVERY_WALLET = {
  Testnet: "https://staging.accounts.meetdapper.com/fcl/authn-restricted",
  Mainnet: "https://accounts.meetdapper.com/fcl/authn-restricted",
};

const SMART_CONTRACTS: SmartContract[] = [
  {
    id:
      Blockchain.NET === "Testnet"
        ? "bc38e065-4faf-43b6-896c-eb93574a541b"
        : "7840037c-e121-4e4e-9f2c-1abcd54e0025",
    name: "ShebaHopeGrows",
    attributes: [
      {
        key: "growth_stage",
        text: "Growth Stage",
        inverted: false,
        options: [{ value: "1" }, { value: "2" }, { value: "3" }],
      },
      {
        key: "scarcity",
        text: "Rarity",
        inverted: false,
        options: [
          { value: "common" },
          { value: "rare" },
          { value: "ultra rare" },
        ],
      },
    ],
    type: "PFP",
    scarcity: null,
    address:
      Blockchain.NET === "Testnet"
        ? "0x520a7157e1b964ed"
        : "0xe0408e51b0b970a7",
    publicCollectionStoragePath: "ShebaHopeGrowsCollectionPublic",
    providerPath: "ShebaHopeGrowsCollection",
  },
];

export {
  MERCHANT_LIST,
  MINT_STORE_MARKET,
  WALLET_VIEWER_ID,
  KYC_MERCHANT_ID,
  DISCOVERY_WALLET,
  SMART_CONTRACTS,
};
