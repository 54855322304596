const en = {
  header: "Personal information",
  firstName: "First name:",
  lastName: "Last name:",
  birthCountry: "Birth country:",
  birthCity: "Birth city:",
  birthday: "Date of birth:",
  gender: "Gender:",
  nationality: "Nationality:",
  address: "Address (number, street):",
  city: "City:",
  zipCode: "Zip code:",
  country: "Country:",
  mobileNumber: "Mobile number:",
  genderOptionMale: "Male",
  genderOptionFemale: "Female",
  options: {
    gender: {
      male: "Male",
      female: "Female",
      other: "Other",
    },
  },
  placeholder: {
    lastName: "Please enter your last name.",
    firstName: "Please enter your first name.",
    birthday: "Please select your date of birth.",
    birthCity: "Please enter your birth city.",
    birthCountry: "Please enter your birth country.",
    gender: "Please select your gender.",
    nationality: "Please choose your nationality.",
    address: "Please enter your address.",
    country: "Please select your country.",
    city: "Please enter your city.",
    zipCode: "Please enter your zip code.",
    mobileNumber: "Please enter your phone number",
  },
  errors: {
    lastName: "You must enter your last name.",
    firstName: "You must enter your first name.",
    birthday: "You must select your date of birth.",
    birthCity: "You must enter your birth city.",
    birthCountry: "You must choose your birth country.",
    gender: "You must select your gender.",
    nationality: "You must choose your nationality.",
    address: "Please select your address.",
    country: "Please select your country.",
    city: "Please enter your city.",
    zipCode: "Please enter your zip code.",
    mobileNumberRequired: "Please enter your phone number.",
    mobileNumberInvalid: "Please enter a valid phone number",
  },
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
