const en = {
  next: "Next",
  submit: "Submit",
  previous: "Previous",
  errors: {
    submissions:
      "An error has occurred when sending your form. Please try again later.",
  },
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
