import SmallNFTCard from "components/AppComponent/SmallNFTCard";
import Field from "components/Widgets/FieldInput";
import Modal from "components/Widgets/Modal";
import { ClientConfig } from "config";
import { AvailableLanguage } from "lib/contexts/LanguageContext";
import { NftPreview } from ".";
import customStyles from "./custom.module.scss";

export type Recipient = {
  name: string | null;
  address: string | null;
};

export type RecipientQueryInfo = {
  onGetRecipient: ({ nameOrAddress }: { nameOrAddress: string }) => void;
  recipient?: Recipient;
  isLoadingRecipient: boolean;
  isRecipientUninitialized: boolean;
  recipientReset?: () => void;
};

type Props = {
  recipientStep: RecipientQueryInfo;
  recipientValue: string;
  setRecipientValue: React.Dispatch<React.SetStateAction<string>>;
  recipientError: boolean;
  onClose: () => void;
  nft: NftPreview;
  strings: ClientConfig["Strings"]["Modals"]["Sell"]["en"];
};

export default function RecipientStep(props: Props) {
  const localizedStrings = props.strings.transferModal.recipientStep;
  return (
    <Modal
      clickOutsideToClose={false}
      isVisible
      btnDisabled={
        !props.recipientValue || props.recipientStep.isLoadingRecipient
      }
      onClose={() => {
        props.recipientStep.recipientReset?.();
        props.onClose();
      }}
      onActionClick={() => {
        props.recipientStep.onGetRecipient({
          nameOrAddress: props.recipientValue,
        });
      }}
      customStrings={{
        actionBtn: localizedStrings.searchRecipient,
      }}
    >
      <h2>{localizedStrings.title}</h2>
      <p>{localizedStrings.subtitle}</p>
      <div className={customStyles.smallNftCard}>
        <SmallNFTCard nft={props.nft as NftPreview} />
      </div>
      <Field
        errorMsg={
          props.recipientError ? localizedStrings.recipientNotFound : null
        }
        className={customStyles.recipientInput}
        placeHolder={localizedStrings.fieldPlaceholder}
        value={props.recipientValue}
        type="text"
        onChange={(e) => {
          props.recipientStep.recipientReset?.();
          props.setRecipientValue(e.target.value);
        }}
      />
    </Modal>
  );
}
