const en = {
  supportMailTo: "mailto:support@mint.store",
  hereLink: "here",
  reachOutToSupportTeamLink: "please reach out to our support team",
  notStarted:
    "You cannot complete any actions on the marketplace before passing a KYC verification. Submit your KYC application",
  started: "You haven't finished your KYC yet. Submit your KYC application",
  aborted:
    "You haven't finish your KYC form in a timely manner. Please Submit a new KYC",
  canceled:
    "Unfortunately, your application has been cancelled by the agent during the verification. To know more about the reason, ",
  failed:
    "Unfortunately, your application has been blocked during the KYC verification. If you think this decision is a mistake, ",
  pending: "Your application is currently under review  ",
  KYCStatusTitle: "KYC Status",
  startedStatusTxt: "Started",
  abortedStatusTxt: "Aborted",
  failedStatusTxt: "Failed",
  canceledStatusTxt: "Canceled",
  successStatusTxt: "Success",
  notStartedStatusTxt: "Not Started",
  pendingStatusTxt: "Review pending",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
