import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useState } from "react";
import RecipientStep, { RecipientQueryInfo } from "./RecipientStep";
import TransferStep, { TransferQueryInfo } from "./TransferStep";

type Step = "recipient" | "transfer";

export interface NftPreview {
  number?: number;
  thumbnail?: string | null;
  name?: string | null;
  editionNumber: string | number | null;
  printingLimit: string | number | null;
  smartContractAddress?: string | null;
  smartContractName?: string | null;
}

type Props = {
  onClose: () => void;
  nft: NftPreview;
  recipientStep: RecipientQueryInfo;
  transferStep: TransferQueryInfo;
};

export default function TransferModal(props: Props) {
  const modalStrings = getLocalizedStrings("Modals", "Sell", useLanguage());
  const [recipientValue, setRecipientValue] = useState<string>("");

  const step: Step =
    !props.recipientStep.isRecipientUninitialized &&
    props.recipientStep.recipient?.address
      ? "transfer"
      : "recipient";

  const recipientError =
    !props.recipientStep.isRecipientUninitialized &&
    props.recipientStep.recipient?.address === null;

  if (step === "recipient") {
    return (
      <RecipientStep
        recipientStep={props.recipientStep}
        recipientValue={recipientValue}
        recipientError={recipientError}
        onClose={props.onClose}
        nft={props.nft}
        strings={modalStrings}
        setRecipientValue={setRecipientValue}
      />
    );
  } else {
    return (
      <TransferStep
        recipient={props.recipientStep.recipient}
        setRecipientValue={setRecipientValue}
        nft={props.nft}
        transferStep={props.transferStep}
        onClose={props.onClose}
        recipientReset={props.recipientStep.recipientReset}
        strings={modalStrings}
      />
    );
  }
}
