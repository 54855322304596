import { AxiosError } from "axios";
import { Blockchain, Project, Config } from "config";
import { SmartContract } from "../types";
import { withPrefix } from "@onflow/fcl";

export const generateInputId = (): string => {
  let id = "";
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const cLenght = chars.length;
  for (let i = 0; i < 3; i++) {
    id += chars.charAt(Math.floor(Math.random() * cLenght));
  }
  return id;
};

export const isSessionExpiredError = (error: Error): boolean => {
  return error.message?.includes("Session expired");
};

export const isNotLoggedInError = (error: Error): boolean =>
  error.message?.includes("Cannot read property 'identity' of undefined");

export const isDeclinedError = (error: Error): boolean =>
  error.message?.includes("Declined: User rejected signature");

export const isValidAddress = (recipient: string): boolean => {
  const addressLength = Blockchain.BLOCKCHAIN_NAME === "flow" ? 16 : 40;
  const filter = new RegExp(`^0x[0-9a-f]{${addressLength}}$`, "i");
  return recipient.startsWith("0x") && Boolean(recipient.match(filter));
};

export const isInvalidAddress = (recipient: string): boolean =>
  recipient.startsWith("0x") && !isValidAddress;

export const isAxiosError = (
  error: unknown
): error is AxiosError<{ message: string }> =>
  !!error &&
  typeof error === "object" &&
  "isAxiosError" in error &&
  (error as { isAxiosError: boolean }).isAxiosError === true;

type FeathersError = {
  className: string;
  code: number;
  data: unknown;
  errors: unknown;
  hook: unknown;
  message: string;
  name: string;
  type: "FeathersError";
  stack: string;
};
export const isFeathersError = (error: unknown): error is FeathersError =>
  !!error &&
  typeof error === "object" &&
  "type" in error &&
  (error as { type: string }).type === "FeathersError";

export const errorToString = (
  error: unknown,
  fallback = "Unexpected error"
): string => {
  if (error instanceof Error) {
    return error.message;
  } else if (typeof error === "string") {
    return error;
  } else {
    return fallback;
  }
};

export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

const medias = ["Video", "3D Object", "Image", "Audio"];
type Media = "Video" | "3D Object" | "Image" | "Audio";

export function isMedia(
  fileType: string | null | undefined
): fileType is Media {
  return fileType != null && medias.includes(fileType);
}

export function capitalize(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const IMAGE_EXTENSIONS = [
  "bmp",
  "gif",
  "ico",
  "jpg",
  "jpeg",
  "png",
  "svg",
  "tif",
  "tiff",
  "webp",
];
const VIDEO_EXTENSIONS = [
  "3g2",
  "3gp",
  "avi",
  "flv",
  "h264",
  "m4v",
  "mkv",
  "mov",
  "mp4",
  "mpg",
  "mpeg",
  "rm",
  "swf",
  "vod",
  "wmv",
];
const AUDIO_EXTENSIONS = [
  "aif",
  "cda",
  "mid",
  "midi",
  "mp3",
  "mpa",
  "ogg",
  "wav",
  "wma",
  "wpl",
];
const MODEL_EXTENSIONS = ["glb"];

export function getFileType(src: string): Media | null {
  src = src.toLowerCase();
  if (IMAGE_EXTENSIONS.find((ext) => src.endsWith(`.${ext}`))) return "Image";
  if (VIDEO_EXTENSIONS.find((ext) => src.endsWith(`.${ext}`))) return "Video";
  if (AUDIO_EXTENSIONS.find((ext) => src.endsWith(`.${ext}`))) return "Audio";
  if (MODEL_EXTENSIONS.find((ext) => src.endsWith(`.${ext}`))) {
    return "3D Object";
  }
  return null;
}

export function getSmartContractFromConfig(
  contractAddress?: string,
  contractName?: string
): SmartContract {
  const smartContract = Project.SMART_CONTRACTS.find(
    (x) => x.name === contractName && x.address === withPrefix(contractAddress)
  );
  if (!smartContract)
    throw new Error(
      `Contract ${contractName}:${contractAddress} is not available for client`
    );
  return smartContract;
}

type CacheInput =
  | {
      itemFID: string;
      contractName: string;
      contractAddress: string;
    }
  | {
      itemFID?: string | null;
      smartContractName?: string | null;
      smartContractAddress?: string | null;
    };

type CacheKey =
  | {
      type: "FlowMintStoreNft";
      id: string;
    }
  | "FlowMintStoreNft";

export function getNftCacheKey(cacheInput: CacheInput): CacheKey {
  if ("contractName" in cacheInput) {
    return {
      type: "FlowMintStoreNft",
      id: `${cacheInput.contractAddress}/${cacheInput.contractName}/${cacheInput.itemFID}`,
    };
  } else {
    if (
      cacheInput.itemFID &&
      cacheInput.smartContractName &&
      cacheInput.smartContractAddress
    ) {
      return {
        type: "FlowMintStoreNft",
        id: `${cacheInput.smartContractAddress}/${cacheInput.smartContractName}/${cacheInput.itemFID}`,
      };
    } else {
      return "FlowMintStoreNft";
    }
  }
}

const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatUsdWithSymbol = (price: number): string => {
  return usdFormatter.format(price);
};

export const formatUsdNoSymbol = (price: number): string => {
  return usdFormatter.format(price).replace("$", "");
};

export const yearNow = new Date().getFullYear();

export const formatWalletName = (
  name: string | null,
  type: WalletType,
  string: string
): string => {
  return name ?? `${type} ${string}`;
};

export const updateBodyOverflow = (scrollDisabled: boolean) => {
  const body = document.getElementById("body");
  if (!body) return;
  body.style.overflow = scrollDisabled ? "hidden" : "auto";
};

export const formatNftName = (name: string) => {
  // Replace chars
  if (Config.Client.NFT_NAME_CHARS_TO_REPLACE) {
    const stringsToReplace = Config.Client.NFT_NAME_CHARS_TO_REPLACE as string;
    stringsToReplace
      .split("")
      .map((char: string) => (name = name.replaceAll(char, " ")));
  }

  // Trim
  name = name.trim();

  return name;
};
