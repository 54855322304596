import { useState } from "react";
import { ItemOption, PanelHeader } from "components/Widgets/ExpansionPanel";
import { Project } from "../../../../../config";
import { withPrefix } from "@onflow/fcl";
import { Flow } from "types";
import { PFPAttributes, PfpFilters } from "types/app-model/global";

export type PfpFilterHeader = Record<
  keyof PfpFilters,
  PanelHeader<Flow.FullNft["attributes"]>[]
>;

const noop = () => {};
const emptyFilter = {
  attributes: [],
  scarcity: [],
};

export function usePfpFilter(
  contractAddress?: string,
  contractName?: string
): {
  headers: PanelHeader<Flow.FullNft["attributes"]>[];
  selectedFilter: PfpFilters;
  handleSelectedFilter: (items: ItemOption[]) => void;
  removeSelectedFilter: (item: ItemOption) => void;
} {
  if (!contractAddress || !contractName)
    return {
      headers: [],
      selectedFilter: emptyFilter,
      handleSelectedFilter: noop,
      removeSelectedFilter: noop,
    };
  const headers = getPFPFilterHeaders(contractAddress, contractName);

  const [selectedFilter, setSelectedFilter] = useState<PfpFilters>(
    defaultSelectedFilter(headers)
  );

  /***
   * Method that handle the splitting of the different filter type
   * If other filter get added we will have to check the keys in attributes
   * @param {ItemOption[]} items
   */
  const handleSelectedFilter = (items: ItemOption[]) => {
    const scarcity = items.filter((item) => item.key === "scarcity");
    const attributes = items.filter((item) => item.key !== "scarcity");
    setSelectedFilter({ scarcity, attributes });
  };

  const removeSelectedFilter = (item: ItemOption) => {
    // This filter key logic will need to change if we add more logic
    const filterKey = item.key === "scarcity" ? item.key : "attributes";
    const newSelected = [...selectedFilter[filterKey]];
    const itemIdx = selectedFilter[filterKey].findIndex(
      (x) => x.value === item.value && x.key === item.key
    );
    newSelected.splice(itemIdx, 1);
    handleSelectedFilter(newSelected);
  };

  return {
    headers: Object.values(headers).flat(),
    selectedFilter,
    handleSelectedFilter,
    removeSelectedFilter,
  };
}

function getPFPFilterHeaders(
  contractAddress: string,
  contractName: string
): PfpFilterHeader {
  const contract = Project.SMART_CONTRACTS.find(
    (x) =>
      x.name === contractName &&
      x.address === withPrefix(contractAddress) &&
      x.type === "PFP"
  );

  if (!contract || contract.type !== "PFP") return emptyFilter;
  const attributesHeaders = contract.attributes.map((attribute) =>
    attributesHeaderConverter(attribute)
  );
  const scarcityHeader = contract.scarcity
    ? [attributesHeaderConverter(contract.scarcity)]
    : [];
  return { scarcity: scarcityHeader, attributes: attributesHeaders };
}

/**
 * This function convert the PFPAttributes into a suitable object the expansion Panel component
 * @param {PFPAttributes} attribute
 * @return {PanelHeader<T>}
 */
function attributesHeaderConverter<T>(
  attribute: PFPAttributes
): PanelHeader<T> {
  return {
    text: attribute.text,
    value: attribute.key,
    multiple: attribute.multiple,
    options: attribute.options.map((option) => ({
      key: attribute.key,
      ...option,
      inverted: attribute.inverted,
    })),
  };
}

function defaultSelectedFilter<T>(headers: PfpFilterHeader): PfpFilters {
  return Object.entries(headers).reduce((prev, current) => {
    const [key, header] = current;
    return {
      ...prev,
      [key]: header
        .flatMap((header) => header.options)
        .filter((option) => option.isSelected),
    };
  }, emptyFilter);
}
