const en = {
  button: {
    login: "Login",
  },
  cardTitle: "Commemorative Ticket",
  noMarketPlaceMsg: "Marketplace available soon.",
  learnMoreLinkTxt: "Learn more",
  historyTitle: "History",
  collectible: "Collectible",
  claimNft: "Claim NFT",
  claimedNft: "Claimed",
  shareNft: "Share",
  sellNft: "Sell",
  transferNft: "Transfer",
  purchasedBy: "Purchased by",
  mintedBy: "Minted",
  you: "You",
  edition: "Edition",
  editions: "Editions",
  editionsRemaining: " Editions Remaining",
  editionsRedeemed: " Editions Redeemed",
  of: "of",
  editUsername: "(Edit username)",
  setUserName: "Set username",
  days: "d",
  hours: "h",
  minutes: "min",
  seconds: "sec",
  lockEndTimePrefixMsg: "Your order is currently pending. Please allow ",
  lockEndTimeSuffixMsg: "before claiming your NFT",
  beforeClaiming: "Before claiming NFT",
  sendAsGift: "Send as Gift",
  placeForSale: "List for Sale",
  applyForKycBtnTxt: "Apply for KYC",
  applyForKycMsgTxt:
    " You must apply for KYC before doing any action on the Marketplace",
  claimNftError: "An error occurred while claiming your NFT",
  withdraw: "Remove From Sale",
  viewOnMarketplace: "View on Marketplace",
  listedOnMarketplace: "Listed on Marketplace",
  currentLowestPrice: "Current lowest price",
  selectEdition: "Select Edition & Purchase",
  notForSale: "This item is not currently for sale.",
  creator: "Creator",
  purchase: "Purchase",
  addFundsAndPurchase: "Add funds and purchase",
  puttingForSale: "Placing for sale...",
  withdrawingFromSale: "Withdrawing from sale...",
  nftMustBeClaimed: "You must claim your NFT to sell or transfer.",
  isClaimProcessing: "Claim in process. It may take a few minutes.",
  walletIsNotOwner: "Connected wallet is not the owner of the NFT",
  listingInProgress: [
    "Listing in Progress. Please refresh in 1 minute.",
    "DO NOT relist.",
  ],
  transferInProgress: [
    "Transfer in Progress. Please refresh in 1 minute.",
    "DO NOT try to transfer again.",
  ],
  withdrawInProgress: [
    "Withdraw in Progress. Please refresh in 1 minute.",
    "DO NOT try to withdraw again.",
  ],
  claimInProgress: [
    "Claim in Progress. Please refresh in 1 minute",
    "DO NOT reclaim.",
  ],
  purchaseInProgress: [
    "Purchase in Progress. Please refresh in 1 minute",
    "DO NOT try to purchase again.",
  ],
  sessionExpired: "Blocto session expired. Please try again to log back in.",
  listingFailed: "Listing failed. Please try again in a few minutes.",
  withdrawFailed: "Withdraw failed. Please try again in a few minutes.",
  purchaseFailed: "Purchase failed. Please try again in a few minutes.",
  transferDisabled: "Transfer is temporarily disabled.",
  transferFailed: "Transfer failed. Please try again.",
  claimFailed:
    "Claim failed. Please try again in a few minutes or with another wallet.",
  walletNotSetup: "The wallet needs to be set up in order to receive NFTs.",
  setupWallet: "Set up wallet",
  connectWallet: "Connect wallet",
  changeWallet: "Change wallet",
  connected: "Connected",
  owner: "Owner",
  insufficientFunds: "Insufficient wallet funds",
  walletBalance: "Wallet balance",
  noNftForSale: "No NFTs for sale",
  login: "login",
  salesHistory: "View Item Activity",
  purchaseWarning:
    "Warning: In high demand situations, many users may be purchasing the same NFT simultaneously. In this case, there is a chance the sale does not complete toward your wallet. Rest assured, no funds will be transferred.",
  redeemNFT: "Redeem your Collectible",
  redemptionLimitReached: "You have reached your redemption limit.",
  globalLimitReached: "There are no more NFTs to redeem.",
  fusdNotSetup: "The current wallet is not setup for FUSD",
  mintStoreItemNotSetup: "The current wallet is not setup for MintStoreItem",
  blockchainMaintenance: "The blockchain is currently under maintenance",
  noWalletDetected: "No wallet detected",
  unclaimable: "NFT not claimable in this environment",
  unclaimableActiveNfts: "",
  goToMarketplace: "",
  goBack: "",
  requestLoginMessage: "Please log in to continue",
  stepList: {
    connect: "Step 1: Connect your Account",
    initialize: "Step 2: Initialize your Account",
    claim: "Step 3: Claim your NFT",
  },
  ownNftInAnotherWalletMsg: "You own this NFT in wallet:",
  requireConsentBeforePurchaseMessageTitle:
    "By continuing you acknowledge that you are only allowed to own one prize eligible Playoff Can.",
  requireConsentBeforePurchaseMessage:
    "If you purchase one of the grand prize eligible Playoff Cans you will have to fill out an affidavit, COVID Release, and tax paperwork. You will also have a background check conducted on yourself and any guests.",
  redeemTitle: "",
  connect: "",
  connectButton: "",
  initialize: "Initialize Wallet",
  viewIn3D: "View In 3D",
  psNote: "",
  flowClaimSteps: {
    stepOne: "Step 1 / 3",
    stepTwo: "Step 2 / 3",
    stepThree: "Step 3 / 3",
  },
  EthClaimSteps: {
    stepOne: "Step 1 / 2",
    stepTwo: "Step 2 / 2",
  },

  walletNotConnected: "Wallet not connected",
  walletNotInitialized: "Wallet not initialized",
  nftNotClaimed: "NFT not claimed",
  stepOneEducationalText:
    "In order to sell your token or to benefit from utility, the token must be linked to a wallet, which validates your ownership on the blockchain.",
  stepTwoEducationalText:
    "Initializing your wallet sets it up to receive tokens from this smart contract. This security measure ensures you don’t receive unwanted tokens.",
  finalEducationalText:
    "Take full ownership of the token by claiming it. This will transfer the token to your wallet",
  tokenInWallet: "Token in Wallet",
  contractAddress: "Contract Address",
  flowScanTransaction: "Flowscan Transaction",
  polygonScanTransaction: "Polygonscan Transaction",
  invalidRedemptionCode: {
    title: "Redemption code is invalid",
    text: "The redemption code you entered is invalid. Make sure you entered the correct spelling!",
    btn: "Try again",
  },
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
