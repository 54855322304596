import Button from "components/Widgets/Button";
import { FunctionComponent, useEffect, useState } from "react";
import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "../style.module.scss";
import { LearnMoreLink } from "../../components";

type TimeLeft = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

interface NftPreview {
  number?: number;
  lockEndTime?: string | null;
  thumbnail?: string | null;
  name?: string | null;
  editionNumber: string | number | null;
  printingLimit: number | null;
  currentAddress?: string;
  mintingStatus?: string;
  editionFID?: string | null;
}

export type ClaimButtonProps = {
  disabledReason?: string;
  nft: NftPreview;
  onClaimClick: () => void;
  isClaimable: boolean;
  setDisplayUnclaimableMessage?: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  enabledButtonWithUnclaimableMessage?: string;
  step?: string;
  loading?: boolean;
};

export const DefaultClaimButtonUI: FunctionComponent<ClaimButtonProps> = (
  props: ClaimButtonProps
) => {
  const localizedStrings = getLocalizedStrings(
    "Pages",
    "Details",
    useLanguage()
  );
  const [timeLeft, setTimeLeft] = useState<TimeLeft | null | undefined>();
  const step = props.step;

  const timeUntilUnlock = () => {
    if (props.nft?.lockEndTime == null) return null;
    const difference = +new Date(props.nft.lockEndTime) - +new Date();
    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return null;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(timeUntilUnlock());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const handleClaimClick = () => {
    if (
      props.enabledButtonWithUnclaimableMessage &&
      props.setDisplayUnclaimableMessage
    ) {
      props.setDisplayUnclaimableMessage(
        props.enabledButtonWithUnclaimableMessage
      );
    } else {
      props.onClaimClick();
    }
  };

  return (
    <>
      <Button
        type="primary"
        disabled={!!props.disabledReason || timeLeft !== null}
        title={props.disabledReason}
        onClick={() => {
          handleClaimClick();
        }}
        loading={props.loading}
      >
        {step === "2" ? localizedStrings.initialize : localizedStrings.claimNft}
      </Button>
      <div className={styles.errorContainer}>
        {timeLeft && (
          <>
            <span>
              {localizedStrings.lockEndTimePrefixMsg}
              {timeLeft.days > 0
                ? `${timeLeft.days} ${localizedStrings.days}`
                : ""}
              {timeLeft.hours > 0
                ? `${timeLeft.hours} ${localizedStrings.hours}`
                : ""}
              {timeLeft.minutes} {localizedStrings.minutes}
              {timeLeft.seconds} {localizedStrings.seconds}
              {localizedStrings.lockEndTimeSuffixMsg}
            </span>
          </>
        )}
      </div>
      <LearnMoreLink />
    </>
  );
};
