import * as fcl from "@onflow/fcl";
import { AppFooter } from "components/AppComponent/AppFooter";
import { BlockchainMaintenanceBanner } from "components/AppComponent/BlockchainMaintenanceBanner/BlockchainMaintenanceBanner";
import Loader from "components/Widgets/BigLoader";
import ScrollToTop from "components/Widgets/ScrollToTop";
import { Blockchain, Config, hasWalletType, Project } from "config";
import {
  EDITION_DETAILS,
  FAQ_ROUTE,
  GATED_FILE_ROUTE,
  GATED_ITEM_ROUTE,
  GATED_PLAYLIST_ROUTE,
  GATED_VIDEO_ROUTE,
  LEGACY_SHOW,
  LOGIN_ROUTE,
  MAGIC_LOGIN_ROUTE,
  MARKETPLACE_MERCHANT_ROUTE,
  MARKETPLACE_PFP_ROUTE,
  MARKETPLACE_ROUTE,
  MARKETPLACE_ROUTES,
  NFT_LIST_ROUTE,
  PLAYLIST_ROUTE,
  PRIVACY_POLICY_ROUTE,
  PUBLIC_NFT_ROUTE,
  REDEEM_ROUTE,
  REDEMPTION_NFT_ROUTE,
  SALES_HISTORY_ROUTE,
  SHOW,
  SHOW_SALES_HISTORY,
  TERMS_ROUTE,
  UTILITY_ROUTE,
  UTILITY_ROUTES,
  VERSION_ROUTE,
} from "config/routes";
import { useAppSelector } from "lib/store/hooks";
import {
  selectAppUser,
  selectIsInitialized,
} from "lib/store/slices/user-slice";
import GatedFile from "pages/GatedFile";
import PlaylistPage from "pages/GatedPlaylist";
import GatedItem from "pages/GatedProduct";
import GatedVideo from "pages/GatedVideo";
import Marketplace from "pages/Marketplace";
import MerchantsPage from "pages/Marketplace/pages/merchants";
import RedemptionNFT from "pages/RedemptionNFT";
import Utility from "pages/Utility";
import FilesPage from "pages/Utility/pages/Files";
import PlaylistsPage from "pages/Utility/pages/Playlists";
import ProductsPage from "pages/Utility/pages/Products";
import VideosPage from "pages/Utility/pages/Videos";
import { FunctionComponent, lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppHeader from "./components/AppComponent/AppHeader";
import NFTContainer from "./pages/NFT/show/NftShowPage";
const VersionPage = lazy(() => import("pages/Version"));
const Login = lazy(() => import("pages/Login/LoginPage"));
const MagicLogin = lazy(() => import("pages/Login/MagicLoginPage"));
const NftList = lazy(() => import("pages/NFT/list/NftListPage"));

const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
const TermsOfService = lazy(() => import("pages/TermsOfService"));
const FAQ = lazy(() => import("pages/FAQ"));
const SalesHistoryPage = lazy(
  () => import("pages/SalesHistory/SalesHistoryPage")
);
const EditionShowPage = lazy(
  () => import("pages/Editions/show/EditionShowPage")
);
const NotFoundPage = lazy(() => import("pages/NotFound/NotFoundPage"));

import { AlertModal } from "components/AppComponent/ActionModals/AlertModal/AlertModal";
import { ChangeChain } from "components/AppComponent/ActionModals/ChangeChain/ChangeChain";
import { SelectWallet } from "components/AppComponent/ActionModals/SelectWallet/SelectWallet";
import { SessionUpdates } from "components/AppComponent/ActionModals/SessionUpdates/SessionUpdates";

import IncognitoModeWarning from "components/AppComponent/IncognitoModeWarning/IncognitoModeWarning";
import { NET } from "config/global/blockchain";
import { AppProvider } from "context/AppContext";
import { flowAPI } from "lib/store/api/flow";
import { Flow } from "types";
import { Views } from "./pages/Marketplace/views";
import RedeemPage from "pages/Redeem";

function AppRouter() {
  return (
    <Routes>
      <Route
        path={LOGIN_ROUTE}
        element={
          <RedirectIfLoggedIn>
            <Login />
          </RedirectIfLoggedIn>
        }
      />
      <Route
        path={MAGIC_LOGIN_ROUTE}
        element={
          <RedirectIfLoggedIn>
            <MagicLogin />
          </RedirectIfLoggedIn>
        }
      />
      {Config.MarketPlace.hasMarketplace &&
        !Config.MarketPlace.redirectMarketplaceLink && (
          <Route path={MARKETPLACE_ROUTE} element={<Marketplace />}>
            <Route index element={<MarketplaceRedirect />} />
            <Route
              path={`${MARKETPLACE_MERCHANT_ROUTE}/${MARKETPLACE_PFP_ROUTE}`}
              element={<Views.Pfp />}
            />
            <Route
              path={MARKETPLACE_MERCHANT_ROUTE}
              element={<Views.Merchant />}
            />
          </Route>
        )}
      <Route path={MARKETPLACE_ROUTES.CREATORS} element={<MerchantsPage />} />
      <Route path={SALES_HISTORY_ROUTE} element={<SalesHistoryPage />} />
      <Route
        path={`${SALES_HISTORY_ROUTE}/${SHOW_SALES_HISTORY}`}
        element={<SalesHistoryPage />}
      />
      <Route
        path={`${EDITION_DETAILS}/${SHOW}`}
        element={<EditionShowPage />}
      />
      <Route path={TERMS_ROUTE} element={<TermsOfService />} />
      <Route path={PRIVACY_POLICY_ROUTE} element={<PrivacyPolicy />} />
      <Route path={FAQ_ROUTE} element={<FAQ />} />
      <Route path={`/${PUBLIC_NFT_ROUTE}`} element={<Outlet />}>
        <Route path={SHOW} element={<NFTContainer />} />
        <Route path={`${LEGACY_SHOW}`} element={<LegacyRedirectShowNft />} />
        <Route index element={<NotFoundPage />} />
      </Route>
      <Route
        path={NFT_LIST_ROUTE}
        element={
          <RequireAuth>
            <NftList />
          </RequireAuth>
        }
      />
      <Route
        path={"/"}
        element={
          <RequireAuth>
            <Navigate replace to={NFT_LIST_ROUTE} />
          </RequireAuth>
        }
      />
      <Route
        path={`${NFT_LIST_ROUTE}/${SHOW}`}
        element={
          <RequireAuth>
            <NFTContainer />
          </RequireAuth>
        }
      />
      <Route path={VERSION_ROUTE} element={<VersionPage />} />
      <Route path={REDEMPTION_NFT_ROUTE} element={<RedemptionNFT />} />
      <Route path={REDEEM_ROUTE} element={<RedeemPage />} />
      {Config.Utility.HAS_UTILITY_PAGE && (
        <>
          <Route path={UTILITY_ROUTE} element={<Utility />} />
          <Route path={UTILITY_ROUTES.PRODUCTS} element={<ProductsPage />} />
          <Route path={UTILITY_ROUTES.PLAYLISTS} element={<PlaylistsPage />} />
          <Route path={UTILITY_ROUTES.VIDEOS} element={<VideosPage />} />
          <Route path={UTILITY_ROUTES.FILES} element={<FilesPage />} />
          <Route path={PLAYLIST_ROUTE} element={<PlaylistPage />} />
          <Route path={GATED_ITEM_ROUTE} element={<GatedItem />} />
          <Route path={GATED_PLAYLIST_ROUTE} element={<PlaylistPage />} />
          <Route path={GATED_VIDEO_ROUTE} element={<GatedVideo />} />
          <Route path={GATED_FILE_ROUTE} element={<GatedFile />} />
        </>
      )}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

function RequireAuth({ children }: { children: JSX.Element }): JSX.Element {
  const isInitialized = useAppSelector(selectIsInitialized);
  const user = useAppSelector(selectAppUser);
  const location = useLocation();
  if (isInitialized && user == null) {
    return <Navigate replace to={LOGIN_ROUTE} state={{ from: location }} />;
  } else {
    return children;
  }
}

type RedirectionPayload = {
  redirectionUrl: string;
};

const useRedirectionPayload = (): string | null => {
  try {
    const [params] = useSearchParams();
    const { redirectionUrl }: RedirectionPayload = JSON.parse(
      atob(params.get("token") ?? "")
    );
    return redirectionUrl;
  } catch (e: unknown) {
    return null;
  }
};

function RedirectIfLoggedIn({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const isInitialized = useAppSelector(selectIsInitialized);
  const user = useAppSelector(selectAppUser);
  const location = useLocation();
  const redirectionUrl = useRedirectionPayload();
  if (isInitialized && user) {
    const redirectionPath =
      redirectionUrl && !redirectionUrl?.startsWith("/login")
        ? redirectionUrl
        : NFT_LIST_ROUTE;
    return <Navigate replace to={redirectionPath} state={{ from: location }} />;
  } else {
    return children;
  }
}
/**
 * This Redirect is because some of the QR code still have the old public/nft/:itemFID path
 * So far all client that had this path in QR code only have one contract so it's safe to assume
 * that if only one contract redirect to new path. We could take MintStoreItem by default to
 * or do an initial fetch
 *
 * @return {JSX.Element}
 * @constructor
 */

function MarketplaceRedirect() {
  const hasMultiMerchants = Project.MERCHANT_LIST[NET].length > 1;
  const merchantFID = Project.MERCHANT_LIST[NET][0].id;
  const profileName = Config.Client.TEAM_NAME || Config.Client.COPYRIGHT;

  return hasMultiMerchants ? (
    <Views.Editions />
  ) : (
    <Navigate
      replace
      to={MARKETPLACE_MERCHANT_ROUTE.replace(":id", merchantFID).replace(
        ":profileName",
        profileName
      )}
    />
  );
}

function LegacyRedirectShowNft() {
  const { id } = useParams();

  return Project.SMART_CONTRACTS.length === 1 ? (
    <Navigate
      replace
      to={`${PUBLIC_NFT_ROUTE}/${Project.SMART_CONTRACTS[0].address}/${Project.SMART_CONTRACTS[0].name}/${id}`}
    />
  ) : (
    <NotFoundPage />
  );
}
const App: FunctionComponent = () => {
  const user = useAppSelector(selectAppUser);
  const [getNonce] = flowAPI.useGetNonceMutation();

  const updateNonce = async () => {
    if (Blockchain.BLOCKCHAIN_NAME === "flow" && user) {
      const { data } = (await getNonce({ user })) as { data: Flow.Nonce };
      if (data?.nonce) {
        const { nonce } = data;
        const appIdentifier = Project.APP_IDENTIFIER[NET] ?? "";
        fcl
          .config()
          .put("fcl.accountProof.resolver", () => ({ appIdentifier, nonce }));
      }
    }
  };

  useEffect(() => {
    updateNonce();
    const interval = setInterval(() => {
      updateNonce();
    }, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [user]);

  return (
    <AppProvider>
      <BrowserRouter>
        <ScrollToTop />
        <AppHeader />
        <Suspense
          fallback={
            <div className="loader-container">
              <Loader show />
            </div>
          }
        >
          <main
            className="d-flex justify-start flex-column flex-grow-1"
            id="modal_root"
          >
            <BlockchainMaintenanceBanner />
            <IncognitoModeWarning />
            <AlertModal />
            <SessionUpdates />
            <SelectWallet />
            {hasWalletType("polygon", "MetaMask") && <ChangeChain />}
            <AppRouter />
          </main>
        </Suspense>

        <AppFooter />
        <ToastContainer />
      </BrowserRouter>
    </AppProvider>
  );
};

export default App;
