const en = {
  alert: {
    of: "of",
    steps: "steps",
    step: "step",
    completed: "completed",
    button: "Complete profile",
  },
  completeProfile: "Complete profile",
  register: {
    title: "Connect or register $WalletType wallet",
    subheader:
      "To buy and sell NFTs you have to connect a $WalletType wallet. If you do not have a $WalletType wallet yet you can register one in the next step. No worries: It is pretty easy.",
    actionBtnTxt: "Connect / Register",
  },
  setup: {
    title: "Set up your $WalletType wallet",
    subtitle:
      "To send and receive NFTs you have to set up your $WalletType wallet to your account.",
    actionBtnTxt: "Set up wallet",
  },
  wyre: {
    step1: {
      title: "Add funds to your wallet",
      subtitle:
        "To purchase NFTs you must have enough balance on your Blocto wallet.",
      actionBtnTxt: "Add funds",
      cancelBtnTxt: "Skip",
      currentBalance: "Current Balance",
    },
    step2: {
      title: "Which provider you want to use?",
      subtitle: "Choose a payment provider to add funds to your wallet",
      cancelBtnTxt: "Back",
    },
    sources: {
      moonpay: "Moonpay",
    },
  },
  profile: {
    title: "Show some personality",
    subtitle:
      "Give yourself a name and upload a profile picture. The name will be shown, if you purchase, sell or transfer NFTs.",
    actionBtnTxt: "Save & Complete",
    cancelBtnTxt: "Skip",
    usernamePlaceholder: "Username",
  },
  smartContract: {
    title: "Connect smart contracts",
    subtitle:
      "The following smart contract(s) need to be connected to your wallet.",
    nameHeader: "Name",
    statusHeader: "Status",
    continueBtn: "Continue",
    closeBtn: "Close",
    connected: "Connected",
    notConnected: "Confirm",
    connecting: "Connecting",
  },
  step: "Step",
  of: "of",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
