import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import { updateBodyOverflow } from "utils/utils";

export type AppUser = {
  createdAt: string;
  email: string;
  id: number;
  profileName: string | null;
  profileImg: string | null;
  resetExpires: string;
  role: "purchaser";
  updatedAt: string;
  primaryBloctoFlowWalletId: number | null;
  primaryDapperWalletId: number | null;
  primaryEthWalletId: number | null;
  wallets: Wallet[];
};

export type FlowUser = { addr: string; loading: boolean };
export type EthUser = { address: string; balance: string; loading: boolean };

interface UserState {
  isInitialized: boolean;
  appUser: AppUser | null;
  flowUser: FlowUser | null;
  ethUser: EthUser | null;
}

const initialState: UserState = {
  isInitialized: false,
  appUser: null,
  flowUser: null,
  ethUser: null,
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setAppUser: (state, action: PayloadAction<AppUser | null>) => {
      state.appUser = action.payload;
      state.isInitialized = true;
      updateBodyOverflow(false);
    },
    clearAppUser: (state) => {
      state.appUser = null;
      state.isInitialized = true;
    },
    setFlowUser: (state, action: PayloadAction<FlowUser>) => {
      state.flowUser = action.payload;
    },
    setFlowUserLoading: (state, action: PayloadAction<boolean>) => {
      if (state.flowUser) {
        state.flowUser.loading = action.payload;
      } else {
        state.flowUser = { addr: "", loading: action.payload };
      }
    },
    clearFlowUser: (state) => {
      state.flowUser = null;
    },
    setEthUser: (state, action: PayloadAction<EthUser>) => {
      state.ethUser = action.payload;
    },
    setEthUserLoading: (state, action: PayloadAction<boolean>) => {
      if (state.ethUser) {
        state.ethUser.loading = action.payload;
      } else {
        state.ethUser = { address: "", balance: "", loading: action.payload };
      }
    },
    clearEthUser: (state) => {
      state.ethUser = null;
    },
  },
});

export const {
  setAppUser,
  clearAppUser,
  setFlowUser,
  clearFlowUser,
  setEthUser,
  setEthUserLoading,
  clearEthUser,
  setFlowUserLoading,
} = userSlice.actions;

export const selectAppUser = (state: RootState): AppUser | null =>
  state.users.appUser;

export const selectFlowUser = (state: RootState): FlowUser | null =>
  state.users.flowUser;

export const selectEthUser = (state: RootState): EthUser | null =>
  state.users.ethUser;

export const selectIsInitialized = (state: RootState): boolean =>
  state.users.isInitialized;

export const userReducer = userSlice.reducer;
