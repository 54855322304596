import { SmartContract } from "types";
import * as Blockchain from "../../../global-blockchain-config";
import { AvailableLanguage } from "lib/contexts/LanguageContext";

const DEFAULT_LANGUAGE: AvailableLanguage = "de";
const ALLOWED_LANGUAGES: AvailableLanguage[] = ["de", "en"];

const MERCHANT_LIST = {
  Testnet: [{ id: "20", address: "0x28492e97618732eb" }],
  Mainnet: [{ id: "36", address: "0xfc44215cea2f5f04" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 0, address: "0x28492e97618732eb" },
  Mainnet: { id: 0, address: "0xfc44215cea2f5f04" },
};

const WALLET_VIEWER_ID = {
  Testnet: 6,
  Mainnet: 26,
};
const KYC_MERCHANT_ID = {
  Testnet: 1,
  Mainnet: 1,
};

const DISCOVERY_WALLET = {
  Testnet: "https://staging.accounts.meetdapper.com/fcl/authn-restricted",
  Mainnet: "https://accounts.meetdapper.com/fcl/authn-restricted",
};

const SMART_CONTRACTS: SmartContract[] = [
  {
    id:
      Blockchain.NET === "Testnet"
        ? "786aa074-64d0-493a-823b-e7d36fa53bff"
        : "7764d1a0-bf2e-4da1-9e57-ba7c208cd935",
    name: "MintStoreItem",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "MintStoreItemCollectionPublic",
    providerPath: "MintStoreItemCollection",
  },
  {
    id:
      Blockchain.NET === "Testnet"
        ? "18486217-2738-4c16-a2cd-f7c1e4f18968"
        : "74794c08-c063-40f2-8918-d761d14ef3d5",
    name: "YoungBoysBern",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "YoungBoysBernCollectionPublic",
    providerPath: "YoungBoysBernCollection",
  },
  {
    id:
      Blockchain.NET === "Testnet"
        ? "934c4b4f-e971-465d-8637-48bfc39c5ecd"
        : "6f185b26-4f8d-475a-b5a5-6302e137959b",
    name: "YBees",
    scarcity: {
      key: "scarcity",
      text: "Rarity",
      multiple: false,
      inverted: false,
      options: [
        { value: "COMMON" },
        { value: "UNCOMMON" },
        { value: "RARE" },
        { value: "EPIC" },
        { value: "LEGENDARY" },
        { value: "MYTHIC" },
      ],
    },
    attributes: [
      {
        key: "background",
        text: "Background",
        multiple: false,
        inverted: false,
        options: [
          { value: "acqua" },
          { value: "clouds" },
          { value: "cream" },
          { value: "dark blue" },
          { value: "dark green" },
          { value: "flowers" },
          { value: "night" },
          { value: "pink" },
          { value: "soft blue" },
          { value: "soft green" },
          { value: "vintage" },
        ],
      },
      {
        key: "body",
        text: "Body",
        multiple: false,
        inverted: false,
        options: [
          { value: "black" },
          { value: "black yellow" },
          { value: "blue" },
          { value: "green" },
          { value: "purple" },
          { value: "red" },
          { value: "yellow" },
        ],
      },
      {
        key: "bows_and_scarfs",
        text: "Bows and Scarfs",
        multiple: false,
        inverted: false,
        options: [
          { value: "mis dahei wankdorf" },
          { value: "one" },
          { value: "two" },
          { value: "three" },
          { value: "four" },
          { value: "five" },
          { value: "six" },
          { value: "seven" },
          { value: "eight" },
          { value: "nine" },
          { value: "we love you" },
          { value: "yb forever" },
        ],
      },
      {
        key: "eyes",
        text: "Eyes",
        multiple: false,
        inverted: false,
        options: [
          { value: "angry" },
          { value: "bored" },
          { value: "closed" },
          { value: "happy" },
          { value: "suprised" },
          { value: "tired" },
          { value: "wide eyed" },
          { value: "XXXX" },
          { value: "XXXX" },
          { value: "XXXX" },
        ],
      },
      {
        key: "glasses",
        text: "Glasses",
        multiple: false,
        inverted: false,
        options: [
          { value: "3D glass" },
          { value: "cute" },
          { value: "diving mask" },
          { value: "monocle" },
          { value: "pilot glasses" },
          { value: "pirate" },
          { value: "sun glasses" },
        ],
      },
      {
        key: "hats",
        text: "Hats",
        multiple: false,
        inverted: false,
        options: [
          { value: "beanie black" },
          { value: "beanie care" },
          { value: "beanie tri color" },
          { value: "cap" },
          { value: "fisher hat" },
          { value: "wikinger beanie" },
          { value: "none" },
        ],
      },

      {
        key: "mouth",
        text: "Mouth",
        multiple: false,
        inverted: false,
        options: [
          { value: "angry" },
          { value: "happy" },
          { value: "laugh" },
          { value: "ohhh" },
          { value: "open with tongue" },
          { value: "sad" },
          { value: "say cheese" },
          { value: "scared" },
          { value: "smile" },
          { value: "tongue" },
        ],
      },
      {
        key: "Bow Tie",
        text: "Bow Tie",
        multiple: false,
        inverted: false,
        options: [{ value: "yes" }, { value: "no" }],
      },
    ],
    type: "PFP",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "YBeesCollectionPublic",
    providerPath: "YBeesCollection",
  },
];

export {
  DEFAULT_LANGUAGE,
  ALLOWED_LANGUAGES,
  MERCHANT_LIST,
  MINT_STORE_MARKET,
  WALLET_VIEWER_ID,
  KYC_MERCHANT_ID,
  DISCOVERY_WALLET,
  SMART_CONTRACTS,
};
