import LoaderCircle from "components/Widgets/LoaderCircle";
import { RedemptionCodeData } from "types";
import styles from "./style.module.scss";

export interface NftPreview {
  id?: number;
  thumbnail?: string | null;
  name?: string | null;
  editionNumber: string | number | null;
  printingLimit: string | number | null;
}

type Props = {
  nft?: NftPreview | null;
  loading?: boolean;
  success?: boolean;
  redemptionCodeData?: RedemptionCodeData | null;
};

export default function SmallNFTCard({
  nft,
  loading,
  success,
  redemptionCodeData,
}: Props) {
  const redemptionCodeThumnail = redemptionCodeData?.edition.thumbnail;
  const redemptionCodeName = redemptionCodeData?.edition?.name;
  const loaderClassName = success
    ? `${styles.loader} ${styles.success}`
    : `${styles.loader}`;

  /**
   * ┌───────────┐
   * │ Rendering │
   * └───────────┘
   */
  const renderNftDescription = (nft: NftPreview | null | undefined) => {
    // This is a PFP
    if (nft && "attributes" in nft) {
      // @ts-ignore
      return `#${nft.itemFID}`;
    }

    return (
      <>
        {nft && (
          <>
            {`#${nft?.editionNumber ? nft?.editionNumber : ""}`}
            {nft?.printingLimit && `/ ${nft?.printingLimit}`}
          </>
        )}
      </>
    );
  };
  return (
    <div className={`${styles.card} mt-0 mb-2`}>
      <div>
        <img src={nft?.thumbnail ?? redemptionCodeThumnail ?? ""} />
      </div>
      <h4>{nft?.name ?? redemptionCodeName}</h4>
      <h5>{renderNftDescription(nft)}</h5>
      {(loading || success) && (
        <div className={loaderClassName}>
          <div />
          {loading && <LoaderCircle className={styles.circle} />}
        </div>
      )}
    </div>
  );
}
