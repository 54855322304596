import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import axios from "axios";
import { Config, Project } from "config";
import feathersClient from "lib/feathers";
import { Response } from "types";
import { AppDispatch } from "..";
import { AppUser, clearAppUser, setAppUser } from "../slices/user-slice";
import { ethAPI } from "./eth";
import { flowAPI } from "./flow";
import { rulesetAPI } from "./ruleset";
import { utilityAPI } from "./utility";

export const authenticationAPI = createApi({
  reducerPath: "authenticationAPI",
  baseQuery: fetchBaseQuery(),
  tagTypes: ["login"],
  endpoints: (builder) => ({
    tryLoginWithJWT: builder.mutation<AppUser | null, void>({
      async queryFn(_, { dispatch }) {
        const user: AppUser | null = await feathersClient
          .reAuthenticate()
          .then(({ user }) => user)
          .catch(() => {
            localStorage.removeItem("feathers-jwt");
            return null;
          });
        dispatch(setAppUser(user));
        return { data: user };
      },
    }),
    requestLoginCode: builder.mutation<
      Response.LoginCode,
      { email: string; isRedemption: boolean }
    >({
      async queryFn({ email, isRedemption }) {
        const data = feathersClient.service("login").create({
          email,
          template: isRedemption
            ? Config.Email.REDEMPTION_TEMPLATE
            : Config.Email.EMAIL_TEMPLATE,
          origin: window.location.origin,
          redirectionUrl: window.location.pathname,
        });
        return { data };
      },
    }),
    loginWithCode: builder.query<AppUser, { email: string; code: string }>({
      async queryFn({ email, code }, { dispatch }) {
        try {
          const { user } = await feathersClient.authenticate({
            strategy: "local",
            email,
            password: code,
          });

          dispatch(utilityAPI.util.resetApiState());
          dispatch(rulesetAPI.util.resetApiState());
          dispatch(setAppUser(user));
          return { data: user };
        } catch (error) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: "Invalid Code",
            },
          };
        }
      },
      keepUnusedDataFor: 0,
    }),
    logout: builder.mutation<null, void>({
      async queryFn(_, { dispatch }) {
        await feathersClient.logout();
        dispatch(clearAppUser());
        dispatch(flowAPI.util.resetApiState());
        dispatch(ethAPI.util.resetApiState());
        dispatch(utilityAPI.util.resetApiState());
        dispatch(rulesetAPI.util.resetApiState());
        return { data: null };
      },
    }),
    setUsername: builder.mutation<AppUser, { id: number; profileName: string }>(
      {
        async queryFn({ id, profileName }, { dispatch }) {
          const user = await feathersClient
            .service("users")
            .patch(id.toString(), { profileName });
          dispatch(setAppUser(user));
          return { data: user };
        },
      }
    ),

    setProfileImg: builder.mutation<AppUser, { id: number; file: File }>({
      async queryFn({ id, file }, { dispatch }) {
        const { accessToken } = await feathersClient.get("authentication");
        if (accessToken == null) {
          dispatch(clearAppUser());
          throw new Error("User not authenticated");
        }

        // Request upload URL
        const { data } = await axios.post<{
          signedUploadUrl: string;
          storedFileUrl: string;
        }>(
          `${Project.CLAIM_API_URL}/create-upload-url`,
          { type: "profile" },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );

        // Upload file using the upload URL
        await axios.put(data.signedUploadUrl, file, {
          headers: { "Content-Type": file.type },
        });

        // Save the uploaded file's URL to the database
        const user = await feathersClient
          .service("users")
          .patch(id.toString(), { profileImg: data.storedFileUrl });
        dispatch(setAppUser(user));
        return { data: user };
      },
    }),

    deleteProfileImg: builder.mutation<AppUser, { id: number }>({
      async queryFn({ id }, { dispatch }) {
        const user = await feathersClient
          .service("users")
          .patch(id.toString(), { profileImg: null });
        dispatch(setAppUser(user));
        return { data: user };
      },
    }),
  }),
});

export function setupAuthenticationListeners(dispatch: AppDispatch): void {
  // Try to log back in when first starting the app
  dispatch(authenticationAPI.endpoints.tryLoginWithJWT.initiate());
}
