import Loader from "components/Widgets/BigLoader";
import { ClientConfig, getLocalizedStrings } from "config";
import { AvailableLanguage, useLanguage } from "lib/contexts/LanguageContext";
import { useRuleset } from "lib/store/api/ruleset/hooks";
import { useGatedVideo } from "lib/store/api/utility/hooks";
import NotFoundPage from "pages/NotFound/NotFoundPage";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { Rule } from "types/ruleset";
import VideoUI from "./ui/VideoUI";

export type Props = {
  ruleset?: Rule;
  strings: ClientConfig["Strings"]["Pages"]["GatedPage"]["en"];
};

const GatedVideo: FunctionComponent = () => {
  const strings = getLocalizedStrings("Pages", "GatedPage", useLanguage());
  const { id } = useParams();

  const {
    data: video,
    isLoading,
    isError,
  } = useGatedVideo((id ?? "").toString());
  const { data: ruleset } = useRuleset(video?.rulesetId);

  if (isLoading) {
    return <Loader show />;
  }

  if (!video || isError) {
    return <NotFoundPage />;
  }

  return <VideoUI video={video} ruleset={ruleset} strings={strings} />;
};

export default GatedVideo;
