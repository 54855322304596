import axios from "axios";
import { Blockchain, Project } from "config";
import feathersClient from "lib/feathers";
import { Paginated } from "lib/store/hooks";
import { EthUser } from "lib/store/slices/user-slice";
import {
  Eth,
  RedemptionCodeData,
  Response,
  UserRedemptionStatusData,
} from "types";
import { isDefined, isFeathersError } from "utils/utils";
import { Builder } from "./builder";
import { getMintStoreContract } from "./web3";
const MERCHANT_IDS = Project.MERCHANT_LIST[Blockchain.NET].map((x) => x.id);

export const ethNftEndpoints = (builder: Builder) => ({
  getPaginatedPurchasedNftList: builder.query<
    Paginated<Eth.PurchasedNft>,
    { page: number; limit?: number }
  >({
    async queryFn({ page, limit }) {
      const { data, total } = await feathersClient
        .service("purchased-eth-nft")
        .find({
          query: {
            $skip: page * (limit ?? 10),
            $limit: limit,
            merchantId: MERCHANT_IDS,
            mintingStatus: {
              $or: [
                "MINTED",
                "PURCHASED",
                "CLAIM_PROCESSING",
                "CLAIM_PROCESSING_ERROR",
              ],
            },
          },
        });
      return { data: { list: data, total } };
    },
    providesTags: (result) =>
      result
        ? [
            ...result.list.map(({ itemEID, contractAddress }) =>
              itemEID != null
                ? {
                    type: "EthNft" as const,
                    id: `${contractAddress}-${itemEID}`,
                  }
                : "EthNft"
            ),
            "EthNft",
          ]
        : ["EthNft"],
  }),

  getClaimedNftList: builder.query<
    Eth.NftCompleteList[],
    { wallet: Eth.Wallet }
  >({
    async queryFn({ wallet }) {
      const { data: ethEditions } = await feathersClient
        .service("eth-editions")
        .find({ query: { merchantId: MERCHANT_IDS } });

      //Remove duplicate contracts as some ETH edidtions have the same contract address
      const uniqueEthEditionsContracts = ethEditions
        .map((edition) => edition.contractAddress)
        .filter((edition, index, self) => self.indexOf(edition) == index);

      const claimedNfts = await Promise.all(
        uniqueEthEditionsContracts
          .filter(isDefined)
          .map(async (contractAddress) => {
            const contract = getMintStoreContract(contractAddress, {
              needAuth: false,
            });

            const nftCount: number = Number.parseInt(
              await contract.methods.balanceOf(wallet.address).call()
            );

            const nfts: Eth.NftCompleteList[] = (
              await Promise.all(
                Array.from(Array(nftCount).keys()).map(async (index) => {
                  const nftId: string = await contract.methods
                    .tokenOfOwnerByIndex(wallet.address, index)
                    .call();
                  const nft = await feathersClient
                    .service("public-eth-nft")
                    .get(nftId, { query: { contractAddress } });

                  if (nft != null) {
                    return { ...nft, wallet };
                  } else {
                    return undefined;
                  }
                })
              )
            ).filter(isDefined);

            return nfts;
          })
      );

      return { data: claimedNfts.flat() };
    },
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ itemEID, contractAddress }) =>
              itemEID != null
                ? {
                    type: "EthNft" as const,
                    id: `${contractAddress}-${itemEID}`,
                  }
                : "EthNft"
            ),
            "EthNft",
          ]
        : ["EthNft"],
  }),

  getNft: builder.query<
    Eth.FullNft,
    { itemEID: string; contractAddress: string }
  >({
    async queryFn({ itemEID, contractAddress }) {
      const publicData = await feathersClient
        .service("public-eth-nft")
        .get(itemEID, { query: { contractAddress, merchantId: MERCHANT_IDS } });

      const ownedData = await feathersClient
        .service("purchased-eth-nft")
        .get(itemEID, { query: { contractAddress, merchantId: MERCHANT_IDS } })
        // The back-end returns a 404 if the NFT was no initially sold to the current user
        // but instead transferred. Should the back-end change its behaviour, or is it ok
        // to eat the thrown error here?
        .catch((e: unknown) => {
          if (isFeathersError(e) && e.code === 404) {
            return undefined;
          } else {
            throw e;
          }
        });

      const { data: history } = await feathersClient
        .service("eth-nft-history")
        .find({ query: { itemEID, contractAddress } })
        // I've had times where the back-end returned a 500. I am not sure when or why this happens,
        // but for now I think returning an empty history works. Here is the "message" field of the
        // error: `"Invalid JSON RPC response: \"error code: 1020\""`.
        .catch((e) => {
          if (isFeathersError(e) && e.message.includes("error code: 1020")) {
            return { data: [] };
          } else {
            throw e;
          }
        });

      const creator = history.length > 0 ? history[history.length - 1] : null;
      const owner = history.length > 0 ? history[0] : null;

      const state = await feathersClient
        .service("eth-nft-state")
        .find({ query: { itemEID, contractAddress } });

      const ownerWalletAddress = Object.entries(state.wallets).find(
        ([, isOwnerWallet]) => isOwnerWallet
      )?.[0];

      const nft: Eth.FullNft = {
        ...publicData,
        ...ownedData,
        history,
        creator,
        owner,
        state,
        currentAddress: ownerWalletAddress,
      };

      return { data: nft };
    },
    providesTags: (result) =>
      result?.itemEID && result?.contractAddress
        ? [
            {
              type: "EthNft",
              id: `${result.contractAddress}-${result.itemEID}`,
            },
          ]
        : ["EthNft"],
  }),

  getNftState: builder.query<
    Eth.NftState,
    { itemEID: string; contractAddress: string; user: EthUser | null }
  >({
    async queryFn({ itemEID, contractAddress, user }) {
      const state = await feathersClient
        .service("eth-nft-state")
        .find({ query: { itemEID, contractAddress } });

      const contract = getMintStoreContract(contractAddress, {
        needAuth: false,
      });

      // Do not trust mint-store-feathers's response for nftState.isOwner.
      // Instead, verify yourself by asking the blockchain!
      const ownerAddress: string = await contract.methods
        .ownerOf(itemEID)
        .call();
      const isOwner = state.isOwner || ownerAddress === user?.address;

      return { data: { ...state, isOwner } };
    },
    providesTags: (_, __, { itemEID, contractAddress }) => [
      { type: "EthNftState", id: `${contractAddress}-${itemEID}` },
    ],
  }),

  getRedemptionCodeData: builder.query<RedemptionCodeData, string>({
    async queryFn(redemptionCode) {
      const data = await feathersClient
        .service("redemption-code")
        .get(redemptionCode);
      return { data };
    },
  }),
  getUserRedemptionStatusData: builder.query<
    UserRedemptionStatusData,
    { redemptionCode: string }
  >({
    async queryFn({ redemptionCode }) {
      const data = await feathersClient
        .service("redemption")
        .get(redemptionCode);
      return { data };
    },
  }),
  redeemNft: builder.mutation<Response.Redeem, { redemptionCode: string }>({
    async queryFn({ redemptionCode }) {
      const { accessToken } = await feathersClient.get("authentication");
      const walletViewerId =
        Blockchain.NET === "Testnet"
          ? Project.WALLET_VIEWER_ID.Testnet
          : Project.WALLET_VIEWER_ID.Mainnet;
      const response = await axios.post<Response.Redeem>(
        `${Project.CLAIM_API_URL}/redeem`,
        {
          redemptionCode,
          walletViewerId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { data: response.data };
    },
    invalidatesTags: ["EthNft"],
  }),
  getPaginatedMerchants: builder.query<
    Paginated<Eth.Merchant>,
    { page: number; profileName?: string; id?: string }
  >({
    async queryFn({ page, profileName, id }) {
      const { data, total } = await feathersClient.service("merchants").find({
        query: {
          $skip: page * 10,
          $limit: 10,
          // If the list is filtered by id, make sure it is one available to this platform
          id: id != null && MERCHANT_IDS.includes(id) ? id : MERCHANT_IDS,
          profileName:
            profileName != null ? { $iLike: `%${profileName}%` } : undefined,
        },
      });
      return { data: { list: data, total } };
    },
    providesTags: (result) =>
      result
        ? [
            ...result.list.map(({ id }) => ({
              type: "EthMerchant" as const,
              id,
            })),
            "EthMerchant",
          ]
        : ["EthMerchant"],
  }),
});
