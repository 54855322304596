import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { ArrowLeft, X } from "react-feather";
import Button from "../Button";
import styles from "./styles.module.scss";
import { updateBodyOverflow } from "utils/utils";

type CustomStrings = {
  actionBtn?: string;
  cancelBtn?: string;
};

type Props = {
  isVisible: boolean;
  alignment?: "left" | "center" | "right";
  size?: "default" | "lg";
  onActionClick?: () => void;
  onCancelClick?: () => void;
  onClose?: () => void;
  onBack?: () => void;
  btnLoading?: boolean;
  btnDisabled?: boolean;
  hideCloseButton?: boolean;
  customStrings?: CustomStrings;
  clickOutsideToClose?: boolean;
  fullscreen?: boolean;
  children: React.ReactNode;
};

const Modal: FunctionComponent<Props> = ({
  isVisible,
  alignment = "center",
  size = "default",
  onActionClick,
  onCancelClick,
  onClose,
  onBack,
  btnLoading = false,
  btnDisabled = false,
  hideCloseButton = false,
  customStrings,
  clickOutsideToClose = true,
  fullscreen = false,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [_isVisible, setIsVisible] = useState(isVisible);

  const modalComponentClass =
    styles.modalComponent + (fullscreen ? ` ${styles.fullscreen}` : "");

  const modalContainerClass = `${styles.modalContainer} ${
    styles[`modalSize_${size}`]
  }`;
  const modalContentClass = `${styles.modalContent} ${
    styles[`modalAlign_${alignment}`]
  }`;

  const _onActionClick = () => {
    if (onActionClick) onActionClick();
  };

  const _onCancelClick = () => {
    if (onCancelClick) onCancelClick();
  };

  const _onClose = () => {
    if (onClose) onClose();
    setIsVisible(false);
    updateBodyOverflow(false);
  };

  const _onBack = () => {
    if (onBack) onBack();
  };

  useEffect(() => {
    setIsVisible(isVisible);
    updateBodyOverflow(_isVisible);
  }, [isVisible, _isVisible]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        _onClose();
      }
    };

    if (isVisible && clickOutsideToClose) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, containerRef]);

  if (!_isVisible) return null;

  return (
    <div className={modalComponentClass}>
      <div className={styles.modalBackdrop}></div>
      <div className={styles.modalWrapper}>
        {!fullscreen && (
          <div className={modalContainerClass} ref={containerRef}>
            {!!onBack && (
              <div className={styles.modalBackIcon} onClick={_onBack}>
                <ArrowLeft size={16} />
              </div>
            )}
            {!hideCloseButton && (
              <div className={styles.modalCloseIcon} onClick={_onClose}>
                <X size={16} />
              </div>
            )}
            <div className={modalContentClass}>
              {children}
              {onActionClick && (
                <Button
                  type="primary"
                  className={styles.footerBtn}
                  onClick={_onActionClick}
                  loading={btnLoading}
                  disabled={btnDisabled}
                >
                  {customStrings?.actionBtn ?? "Okay"}
                </Button>
              )}
              {onCancelClick && (
                <Button
                  type="secondary"
                  className={styles.footerBtn}
                  onClick={_onCancelClick}
                  loading={btnLoading}
                  disabled={btnDisabled}
                >
                  {customStrings?.cancelBtn ?? "Cancel"}
                </Button>
              )}
            </div>
          </div>
        )}
        {fullscreen && (
          <div className={styles.fullscreenModalContainer} ref={containerRef}>
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
