import { NFT_LIST_ROUTE } from "config/routes";
import { ModalVisible } from "pages/NFT/show/NftShowPage";
import { NftPreview } from "pages/NFT/show/types";
import { useNavigate } from "react-router-dom";
import { CustomizedClaimModal } from "./Claim/wrapper";
import { LoginModal } from "./Login";
import PurchaseModal from "./Purchase/PurchaseModal";
import SellModal from "./Sell/SellModal";
import TransferModal from "./Transfer";
import { Recipient } from "./Transfer/RecipientStep";
import WithdrawModal from "./Withdraw/WithdrawModal";
import { updateBodyOverflow } from "utils/utils";

type Props = {
  modalVisible: ModalVisible | null;
  setModalVisible: React.Dispatch<React.SetStateAction<ModalVisible | null>>;
  nft: NftPreview;
  queryResults: {
    claim: {
      isClaimSuccess: boolean;
      isClaimError: boolean;
    };
    withdraw: {
      isWithdrawLoading: boolean;
      isWithdrawSuccess: boolean;
      isWithdrawError: boolean;
      withdrawReset?: () => void;
    };
    listForSale: {
      isListForSaleLoading: boolean;
      isListForSaleSuccess: boolean;
      listForSaleErrorMessage?: string | null;
      listForSaleReset?: () => void;
      lowestPrice?: number | null;
      fees?: number;
      listForSale: (
        price: number,
        contractAddress?: string,
        contractName?: string
      ) => void;
    };
    transfer: {
      recipientStep: {
        onGetRecipient: ({ nameOrAddress }: { nameOrAddress: string }) => void;
        recipient?: Recipient;
        isLoadingRecipient: boolean;
        isRecipientUninitialized: boolean;
        recipientReset?: () => void;
      };
      transferStep: {
        isTransferLoading: boolean;
        isTransferSuccess: boolean;
        transferErrorMessage?: string | null;
        transferReset?: () => void;
        transfer: () => void;
      };
    };
    purchase: {
      isPurchaseLoading: boolean;
      isPurchaseError: boolean;
    };
  };
};

export default function ActionModals(props: Props) {
  const navigate = useNavigate();
  switch (props.modalVisible) {
    case "claim":
      return (
        <CustomizedClaimModal
          onClose={() => props.setModalVisible(null)}
          nft={props.nft}
          queryResult={props.queryResults.claim}
        />
      );
    case "withdraw":
      return (
        <WithdrawModal
          onClose={() => props.setModalVisible(null)}
          onMainButtonClick={() => navigate(NFT_LIST_ROUTE)}
          queryResult={props.queryResults.withdraw ?? undefined}
          nft={props.nft}
        />
      );
    case "sell":
      return (
        <SellModal
          onClose={() => {
            props.setModalVisible(null);
            updateBodyOverflow(false);
          }}
          onMainButtonClick={(price) =>
            props.queryResults.listForSale.listForSale(
              price,
              props.nft.smartContractAddress,
              props.nft.smartContractName
            )
          }
          queryResult={props.queryResults.listForSale}
          nft={props.nft}
          lowestPrice={props.queryResults.listForSale.lowestPrice}
          fees={props.queryResults.listForSale.fees}
        />
      );
    case "transfer":
      return (
        <TransferModal
          onClose={() => {
            props.setModalVisible(null);
          }}
          nft={props.nft}
          recipientStep={props.queryResults.transfer.recipientStep}
          transferStep={props.queryResults.transfer.transferStep}
        />
      );
    case "purchase":
      return (
        <PurchaseModal
          onClose={() => props.setModalVisible(null)}
          onMainButtonClick={() => navigate(NFT_LIST_ROUTE)}
          queryResult={props.queryResults.purchase}
          nft={props.nft}
        />
      );
    case "login":
      return (
        <LoginModal
          onCancel={() => props.setModalVisible(null)}
          onComplete={() => props.setModalVisible(null)}
        />
      );
    default:
      return null;
  }
}
