import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import {
  ChangeEvent,
  FunctionComponent,
  SyntheticEvent,
  useState,
} from "react";
import styles from "../styles.module.scss";
import { Credentials } from "components/AppComponent/ActionModals/Login/types";
import { Button, Field } from "components";
import { authenticationAPI } from "lib/store/api/authentication-api";

type Props = {
  email: string;
  setEmail: (email: string) => void;
  onActionClick: () => void;
  setCredentials: React.Dispatch<React.SetStateAction<Credentials | undefined>>;
};

const EmailStep: FunctionComponent<Props> = ({
  email,
  setEmail,
  onActionClick,
  setCredentials,
}) => {
  const strings = getLocalizedStrings("Pages", "Redeem", useLanguage());
  const [requestLoginCode, { isError }] =
    authenticationAPI.useRequestLoginCodeMutation();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setCredentials((prev) => ({ ...prev, email: e.target.value }));
  };

  const runAction = async () => {
    await requestLoginCode({ email, isRedemption: true });

    onActionClick();
  };

  return (
    <>
      <h2>{strings.email.title}</h2>
      <p>{strings.email.text}</p>

      <form
        onSubmit={(e: SyntheticEvent) => {
          e.preventDefault();
          runAction();
        }}
      >
        <div className={styles.emailContainer}>
          <Field
            required
            outline
            autoComplete={"on"}
            onChange={onChange}
            value={email}
            type={"email"}
            errorMsg={isError ? strings.email.error : ""}
            label={strings.email.placeholder}
            placeHolder={strings.email.placeholder}
          />
        </div>
      </form>

      <Button type="primary" onClick={runAction}>
        {strings.email.actionBtn}
      </Button>
    </>
  );
};

export default EmailStep;
