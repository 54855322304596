import {
  FunctionComponent,
  useEffect,
  useState,
  DragEvent,
  ChangeEvent,
} from "react";
import styles from "./styles.module.scss";
import { useLanguage } from "lib/contexts/LanguageContext";
import { Modal } from "components";
import { getLocalizedStrings } from "config";

const MAX_FILE_SIZE = 1024 * 1024 * 20; // 20MB
const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/gif"];

interface ImageUploadInterface {
  className?: string;
  url?: string | null;
  onSelect: (file: File) => void;
  onDelete: () => void;
  onClose: () => void;
}

export const ImageUpload: FunctionComponent<ImageUploadInterface> = (
  props: ImageUploadInterface
) => {
  // States and refs

  const strings = getLocalizedStrings(
    "Component",
    "ImageUpload",
    useLanguage()
  );
  const [dragging, setDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [url, setUrl] = useState<string | null>(props.url ?? null);
  const [_error, setError] = useState<string | undefined>();

  useEffect(() => {
    if (selectedFile) {
      extractBase64UrlFromFile(selectedFile);
    }
  }, [selectedFile]);

  const uploadFile = async () => {
    // Check file
    if (!selectedFile) {
      setError(strings.noFile);
    }
    // Check type
    else if (!ALLOWED_FILE_TYPES.includes(selectedFile?.type)) {
      setError(strings.invalidFileType);
    }
    // Check size
    else if (selectedFile?.size && selectedFile?.size > MAX_FILE_SIZE) {
      setError(strings.fileTooLarge);
    }

    // All is good
    else {
      props.onSelect(selectedFile);
    }
  };

  const deleteCurrentImg = () => {
    props.onDelete();
  };

  // Utils

  const extractBase64UrlFromFile = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setUrl(reader.result as string);
      setError(undefined);
    };
    reader.onerror = () => {
      setError(strings.errorReadingFile);
    };
    reader.readAsDataURL(file);
  };

  // Handlers

  const onDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const onDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);

    if (event.dataTransfer.files.length === 1)
      handleFilesUpdate(event.dataTransfer.files[0]);
  };

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event || !event.target || !event.target.files) return;
    if (event.target.files.length === 1)
      handleFilesUpdate(event.target.files[0]);
  };

  const handleFilesUpdate = (file: File) => {
    setError(undefined);

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setError(strings.invalidFileType);
      return;
    }
    if (file.size > MAX_FILE_SIZE) {
      setError(strings.fileTooLarge);
      return;
    }

    setSelectedFile(file);
  };

  // Rendering

  let rootClass = styles.holder;
  if (props.className) rootClass += ` ${props.className}`;
  if (dragging) rootClass += ` ${styles.dragging}`;

  return (
    <Modal
      isVisible
      onClose={props.onClose}
      onActionClick={uploadFile}
      onCancelClick={url ? deleteCurrentImg : undefined}
      customStrings={{
        actionBtn: strings.uploadSaveBtn,
        cancelBtn: strings.uploadDeleteBtn,
      }}
    >
      <h2>{strings.uploadTitle}</h2>
      <p>{strings.uploadSubtitle}</p>
      <div className={styles.uploadModalContainer}>
        <div
          className={rootClass}
          style={{ backgroundImage: `url("${url}")` }}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          onChange={_onChange}
        >
          <div className={styles.hoverContent}>
            <span>Upload</span>
          </div>
          <input accept={ALLOWED_FILE_TYPES.join(", ")} type="file" />
        </div>

        <div className={styles.errorContainer}>
          {!!_error && <span>{_error}</span>}
        </div>
      </div>
    </Modal>
  );
};
