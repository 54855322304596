import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "../styles.module.scss";
import Collectible from "components/Widgets/Collectibles/Collectible";
import { Flow } from "types";
import { useNavigate } from "react-router-dom";
import { EDITION_DETAILS, MARKETPLACE_ROUTE } from "config/routes";
import CollectiblesSection from "components/Widgets/Collectibles/CollectiblesSection";
import { formatUsdWithSymbol } from "utils/utils";
import { withPrefix } from "@onflow/fcl";

type Props = {
  editions: Flow.EditionForSale[];
  totalEditions: number;
  setEditionsPage: React.Dispatch<React.SetStateAction<number>>;
};

export default function Marketplace({ editions }: Props) {
  const strings = getLocalizedStrings("Pages", "Utility", useLanguage());
  const navigate = useNavigate();

  return (
    <section className={styles.sectionContainer}>
      <CollectiblesSection
        title={strings.marketplace.title}
        description={strings.marketplace.description}
        displayShowAll={true}
        showAllText={strings.marketplace.showAllText}
        onShowAll={() => navigate(MARKETPLACE_ROUTE)}
      >
        {editions.map((edition, i) => (
          <Collectible
            key={i}
            title={edition.name ?? ""}
            description={`${edition.itemsForSale} ${strings.marketplace.forSale}`}
            imageSrc={edition.thumbnail ?? undefined}
            footer={
              edition.lowestPrice != null && (
                <p>
                  {`${strings.marketplace.priceFrom}`}{" "}
                  {formatUsdWithSymbol(edition.lowestPrice)}
                </p>
              )
            }
            onClick={() =>
              navigate(
                `${EDITION_DETAILS}/${withPrefix(
                  edition.smartContractAddress
                )}/${edition.smartContractName}/${edition.editionFID}`
              )
            }
          />
        ))}
      </CollectiblesSection>
    </section>
  );
}
