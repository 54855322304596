const en = {
  loadingTitle: "Redemption in progress",
  loadingSubtitle: "This may take a few minutes.",
  pleaseWait: "Please wait...",
  successfulTitle: "Congratulations!",
  successfulSubtitle: "The item has successfully been redeemed.",
  itemNowInWallet: "It is now in your wallet.",
  errorTitle: "Redemption unsuccessful",
  errorSubtitle: "An unexpected error has occured.",
  tryAgainLater: "Please try again in a few minutes.",
  viewCollectible: "View your Collectible",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
