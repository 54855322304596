import Loader from "components/Widgets/BigLoader";
import { ClientConfig, getLocalizedStrings } from "config";
import { AvailableLanguage, useLanguage } from "lib/contexts/LanguageContext";
import { useRuleset } from "lib/store/api/ruleset/hooks";
import { useGatedPlaylist } from "lib/store/api/utility/hooks";
import NotFoundPage from "pages/NotFound/NotFoundPage";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { Rule } from "types/ruleset";
import PlaylistUI from "./ui/PlaylistUI";

export type Props = {
  ruleset?: Rule;
  strings: ClientConfig["Strings"]["Pages"]["GatedPage"]["en"];
};

const GatedPlaylist: FunctionComponent = () => {
  const strings = getLocalizedStrings("Pages", "GatedPage", useLanguage());
  const { id } = useParams();

  const { data: playlist, isLoading, isError } = useGatedPlaylist(id ?? "");
  const { data: ruleset } = useRuleset(playlist?.rulesetId);

  if (isLoading) {
    return <Loader show />;
  }

  if (!playlist || isError) {
    return <NotFoundPage />;
  }

  return <PlaylistUI playlist={playlist} ruleset={ruleset} strings={strings} />;
};

export default GatedPlaylist;
