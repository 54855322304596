import { yearNow } from "utils/utils";
import LoginSrc from "../img/login-photo.png";
import LogoSvg from "../img/logo.svg";
import LogoPng from "../img/logo16.png";

const HTML_TITLE = "Default Wallet Viewer";
const TEAM_CITY = "Chicago";
const TEAM_NAME = "Bulls";
const COPYRIGHT_YEAR = `${yearNow}`;
const COPYRIGHT = "Bulls";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;
const CONTACT_US = "support@mint.store";
const DISPLAY_INITIAL_LOGIN_PAGE = true;
const DIGITAL_PASSPORT_LINK = "";

const WALLET_FAQ_LINK =
  "https://mintstore.zendesk.com/hc/en-us/articles/9841968120093-What-is-a-wallet-";

const NFT_NAME_CHARS_TO_REPLACE: string | null = null;

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT_YEAR,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
  LOGO_HEIGHT_PX: null as number | null,
  CONTACT_US,
  DISPLAY_INITIAL_LOGIN_PAGE,
  HIDE_NFT_OWNER_AND_CREATOR: false,
  CUSTOM_FOOTER: null as string | null,
  HIDE_NFT_INFO_CHIPS: false,
  HIDE_PFP_TRAITS: false,
  CUSTOM_NFT_NAME: null as string | null,
  HIDE_SPECIFIC_ITEM_PRICE: false,
  HIDE_EDITION_LOWEST_PRICE: false,
  HIDE_NFT_LIST_TABS: false,
  HIDE_TRANSFER_BUTTON: false,
  HIDE_TRANSFER_BUTTON_FOR_NFT_SCARCITIES: new Array<string>(),
  HIDE_GO_TO_MARKETPLACE_BUTTON: true,
  HIDE_PRINTING_LIMIT: false,
  HIDE_WALLET_HISTORY: true,
  DISABLE_PURCHASE_IF_STATUS_TRAIT_ACTIVE: false,
  REQUIRE_CONSENT_BEFORE_PURCHASE: false,
  PERSIST_FLOW_USER_IN_LOCAL_STORAGE: false,
  DISPLAY_SCARCITY: false,
  DISPLAY_BREADCRUMBS: false,
  SHOW_CLAIMING_STEP_LIST: false,
  REDEMPTION_SHOW_REMAINING: true,
  REDEMPTION_SHOW_REDEEMED: false,
  REDEMPTION_CAMPAIGN_SHOW_REMAINING: false,
  REDEMPTION_CAMPAIGN_SHOW_REDEEMED: false,
  WALLET_FAQ_LINK,
  CUSTOM_HEADER_LINKS: new Array<{ name: string; link: string }>(),
  DIGITAL_PASSPORT_LINK,
  NFT_NAME_CHARS_TO_REPLACE,
};
