const en = {
  kycSuccessTitle: "KYC Succeeded",
  kycSuccessMsg: "You can now access the marketplace functionality",
  kycStartedTitle: "KYC Started",
  kycStartedMsg:
    "You can now proceed to the video identification in the new opened tab or you can click. To reactivate your KYC request click",
  kycAbortedTitle: "KYC Aborted",
  kycAbortedMsg:
    "Your video identification was not complete in a timely manner",
  kycReviewPendingTitle: "KYC Under Review",
  kycReviewPendingMsg:
    "Your KYC is being processed. Your results will be available shortly",
  redirectText: "here",
  redirectCountdownTxt: "second(s) before being redirect",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
