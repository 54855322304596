const en = {
  header: "Which asset classes do you have knowledge on?",
  options: {
    "8": "Warrants",
    "17": "Securities",
    "3": "Bonds",
    "4": "Shares",
    "10": "Hedge Funds",
    "5": "Certificates",
    "0": "Closed-end investment funds",
  },
  errors: {
    selectRequired: "Please make a selection.",
  },
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
